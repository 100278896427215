import {valtioProxy} from '@/utils/valtio';
import {useParams} from 'react-router';
import type {FC} from 'react';
import React from 'react';
import {observer} from 'mobx-react-lite';

export const routerParamsProxy = valtioProxy<any>(null);

export const GlobalRouterStateUpdater: FC = observer(function GlobalRouterStateUpdater() {
  const params = useParams();
  routerParamsProxy.value = params;
  return null;
});
